<script setup>

import { reactive } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useProfileActions } from '@profile';
import { useLeadActions } from '@lead';
import { useFinanceActions } from '@finance';

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getDepositResource = useFinanceActions('getDepositResource');
const deposits = getDepositResource(
    { lead_id: [lead.model.id] },
    true,
    'LeadDeposits Component'
);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const errors = reactive({});

const statuses = [
    { slug: 'new', name: 'New' },
    { slug: 'suspend', name: 'Suspend' },
    { slug: 'processed', name: 'Processed' },
    { slug: 'refund', name: 'Refund' },
    { slug: 'repaid', name: 'Repaid' }
];

function changeStatus(index, value) {
    const previousValue = deposits.state[index].status;
    deposits.save(deposits.state[index].id, {
        status: value
    }).then(() => {
        if (value === 'processed') {
            lead.model.balance = Number(lead.model.balance) + Number(deposits.state[index].amount);
        } else if (previousValue === 'processed') {
            lead.model.balance -= Number(deposits.state[index].amount);
        }
    }).catch(({ response }) => {
        if (response.status === 403) {
            errors[deposits.state[index].id] = 'This action is unauthorized';
        }
    });
}

function removeDeposit(id) {
    PopUp.open(require('@finance/components/PopUps/RemoveDeposit'))
    .setCaption(i18n.global.t('finances.remove_replenishment', 'Remove replenishment'))
    .setPayload({ payload: { id } });
}

function forwarded(id) {
    deposits.save(id, {
        is_forwarded: 'Yes'
    }).then(() => {
        deposits.state.find(deposit => deposit.id === id).is_forwarded = 'Yes';
    });
}

function refresh(id) {
    deposits.show(id);
}
</script>

<template>
    <div class="deposits">
        <g-preloader v-if="deposits.isLoading"/>
        <g-caption class="info" weight="500" size="4" v-if="!deposits.state.length && !deposits.isLoading">
            <div>{{$t('base.no_deposits')}}</div>
        </g-caption>
        <template v-if="!deposits.isLoading">
            <div v-for="(deposit, i) in deposits.state"
                 v-bind:key="`deposit-${deposit.id}`"
                 class="card"
                 v-bind:class="[deposit.type, {forwarded: deposit.is_forwarded === 'Yes'}]"
                 v-bind:data-type="deposit.type || 'Deposit'">

                <g-button v-if="profile.can('Deposit Remove')" class="actions remove-btn with-tooltip"
                          v-bind:data-tooltip="$t('finances.remove_replenishment', 'Remove replenishment')"
                          v-on:click="removeDeposit(deposit.id)">
                    <g-symbol name="delete" width="16" height="16"/>
                </g-button>

                <!-- type -->
                <div class="type">
                    <div class="title"></div>
                    <span v-if="deposit.is_ftd === 'Yes'" class="value">
                    <span class="test">{{ deposit.is_test === 'Yes' ? 'Test' : '' }}</span>
                    {{ deposit.type === 'deposit' ? 'FTD' : $t(`finances.${deposit.type}`) }}
                </span>
                    <span v-else class="value">
                    <span class="test">{{ deposit.is_test === 'Yes' ? 'Test' : '' }}</span>
                    {{ deposit.type === 'deposit' ? $t('finances.replenishment') : $t(`finances.${deposit.type}`) }}
                </span>
                </div>
                <!--/ TYPE -->

                <!-- IS FORWARDED -->
                <div class="is_forwarded" v-if="deposit.is_forwarded !== 'Yes'">
                    <g-checkbox v-on:change="forwarded(deposit.id)">Is Forwarded</g-checkbox>
                </div>
                <!--/ IS FORWARDED -->
                <!-- NAME -->
                <div v-else-if="!!deposit.details?.name" class="name">
                    <div class="title">Name:</div>
                    <span class="value">{{ deposit.details.name }}</span>
                </div>
                <!--/ NAME -->


                <!-- ADDRESS -->
                <div class="address">
                    <div class="title">From Address</div>
                    <g-copy v-if="deposit.address" class="value" v-bind:text="deposit.address"></g-copy>
                </div>
                <!--/ ADDRESS -->

                <!-- AMOUNT -->
                <div class="amount">
                    <div class="title">Amount:</div>
                    <span class="value">
<!--					{{ Math.round(deposit.amount / deposit.rate, 8) }}-->
					{{ Number((deposit.amount / deposit.rate).toFixed(5)) }}
					<small class="currency" v-bind:class="deposit.currency.toLowerCase()">{{ deposit.currency }}</small>

                    <small v-if="deposit.rate !== 1" class="rate"> ✕ {{ deposit.rate }}</small>
				</span>
                </div>
                <!--/ AMOUNT -->

                <!-- STATUS -->
                <div class="status" v-bind:class="deposit.status">
                    <g-select v-model="deposit.status"
                              label="Status"
                              option-text="name"
                              option-value="slug"
                              v-bind:options="statuses"
                              v-on:select="changeStatus(i, $event)"
                              v-bind::disabled="deposit.status === 'refund'"
                              v-bind:error="errors[deposit.id]"
                              v-bind:readonly="profile.cant('Deposit Edit')"/>
                </div>
                <!--/ STATUS -->

                <div v-if="deposit.user">
                    <div class="title">Recipient:</div>
                    <span class="value">
					{{ deposit.user.name }}
				</span>
                </div>
                <div v-else></div>

                <!-- METHOD -->
                <div class="method">
                    <div class="title">{{ $t('finances.method', 'Method') }}:</div>
                    <span class="value">{{ deposit.method }}</span>
                </div>
                <!--/ METHOD -->

                <template v-if="!!deposit.details">
                    <!-- DETAILS -->

                    <!-- USDT AMOUNT -->
                    <div class="usdt_amount">
                        <template v-if="(deposit.rate) !== 1">
                            <div class="title">USDT Amount:</div>
                            <span class="value">{{ Number(deposit.amount).toFixed(2) }} <small class="currency usdt">USDT</small></span>
                        </template>
                    </div>
                    <!--/ USDT AMOUNT -->


                    <!-- EXP -->
                    <g-flex class="exp" gap="20">
                        <div>
                            <div class="title">Exp:</div>
                            <span class="value">{{ deposit.details.exp }}</span>
                        </div>
                        <div>
                            <div class="title">cvv:</div>
                            <span class="value">{{ deposit.details.cvv }}</span>
                        </div>
                    </g-flex>
                    <!--/ EXP -->

                    <!-- DATE -->
                    <div class="date">
                        <div class="title">Created at:</div>
                        <span class="value">{{ deposit.created_at }}</span>
                    </div>
                    <!--/ DATE -->

                    <!-- OTP -->
                    <div class="otp">
                        <div class="title">OTP Code:</div>
                        <b v-if="deposit.details.otp_code" class="value">{{ deposit.details.otp_code }}</b>
                        <g-symbol v-else class="refresh-btn" name="refresh" width="20" height="20" v-on:click="refresh(deposit.id)"/>
                    </div>
                    <!--/ OTP -->
                </template>
                <template v-else>
                    <!-- USDT AMOUNT -->
                    <div v-if="(deposit.rate) !== 1" class="usdt_amount">
                        <div class="title">USDT Amount:</div>
                        <span class="value">{{ Number(deposit.amount).toFixed(2) }} <small
                            class="currency usdt">USDT</small></span>
                    </div>
                    <!--/ USDT AMOUNT -->

                    <!-- DATE -->
                    <div class="date">
                        <div class="title">Created at:</div>
                        <span class="value">{{ deposit.created_at }}</span>
                    </div>
                    <!--/ DATE -->

                    <!-- SUB METHOD -->
                    <div class="sub_method">
                        <div class="title">{{
                                deposit.method === 'Crypto' ? 'Network' : $t('finances.sub_method', 'SubMethod')
                            }}:
                        </div>
                        <span class="value">{{ deposit.sub_method }}</span>
                    </div>
                    <!--/ SUB METHOD -->
                </template>
            </div>
        </template>
    </div>
</template>

<style scoped lang="scss">
.deposits {
    & > .card {
        display: grid;
        grid-gap: 10px;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1.4fr 0.6fr;
        margin: 6px 0;
        padding: 18px;
        position: relative;
        color: var(--title-color, $title-color);
        border-radius: var(--bar-rounding, $bar-rounding);
        background-color: var(--bar-bg, $bar-bg);
        box-shadow: var(--bar-shadow, $bar-shadow);
        border: 2px solid transparent;

        &:not(.forwarded) {
            background-color: #351525;
        }

        .title {
            font-size: 0.9rem;
            color: var(--main-text-color, $main-text-color);
        }

        .value {
            white-space: nowrap;
        }

        .type {
            grid-column: 1/2;
            font-size: 15px;
            text-transform: capitalize;
        }

        .is_forwarded {
            grid-column: 2/3;
        }

        .status {
            &.new > .g-select:deep(fieldset) {
                border-color: var(--primary-hover, $primary-hover);

                & > legend {
                    color: var(--primary-hover, $primary-hover);
                }
            }

            &.suspend > .g-select:deep(fieldset) {
                border-color: var(--warning-hover, $warning-hover);

                & > legend {
                    color: var(--warning-hover, $warning-hover);
                }
            }

            &.processed > .g-select:deep(fieldset) {
                border-color: var(--success-hover, $success-hover);

                & > legend {
                    color: var(--success-hover, $success-hover);
                }
            }

            &.refund > .g-select:deep(fieldset) {
                border-color: var(--separator-color, $separator-color);

                & > legend {
                    color: var(--disabled-color, $disabled-color);
                }
            }
        }

        .address {
            grid-column: 3/5;
        }

        .actions {
            top: -5px;
            right: 5px;
            position: absolute;
            cursor: pointer;
            background-color: transparent;
            fill: var(--main-text-color, $main-text-color);

            &:hover {
                fill: var(--title-text-color, $title-text-color);

                &::after {
                    left: 50%;
                    transform: translateX(-85%);
                    top: calc(95% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                    background-color: var(--danger, $danger);
                }

                &::before {
                    transform: translateX(-50%);
                    left: 50%;
                    top: calc(95% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                    border-bottom-color: var(--danger, $danger);
                }
            }
        }

        .date {
            grid-column: 3/4;
        }

        &.bonus {
            border-color: var(--success, $success);

            & > .type {
                color: var(--success-hover, $success-hover);
            }
        }

        &.repayment {
            border-color: var(--warning, $warning);

            & > .type {
                color: var(--warning, $warning);
            }
        }

        &.fake,
        &.credit {
            border-color: var(--danger, $danger);

            & > .type {
                color: var(--danger-hover, $danger-hover);
            }
        }
    }
}

.test {
    color: var(--disabled-color, $disabled-color);
}

.rate,
.currency {
    font-size: 0.8rem;
}

.refresh-btn {
    cursor: pointer;
    margin: 0 auto;
    fill: var(--main-text-color, $main-text-color);

    &:hover {
        fill: var(--title-text-color, $title-text-color);
    }
}

.btc {
    color: $btc;
    font-weight: 700;
}

.usdt {
    color: $usdt;
    font-weight: 700;
}

.eur {
    color: $eur;
    font-weight: 700;
}

.eth {
    color: $eth;
    font-weight: 700;
}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    margin-top: 50%;

    & > div {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
}
</style>