<script setup>

import { defineEmits, reactive, ref, computed } from 'vue';
import { useProfileActions } from '@profile';
import { useShipActions } from '@/Ship';
import {useSettingsActions} from "@settings";

const emit = defineEmits(['save']);

const onlyNumberInput = useShipActions('onlyNumberInput');

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('trading');

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const brandName = ref(profile.brands[0].name);
const payload = reactive({});

let timeout = null;
function save() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        settings.save('trading', payload).then(() => {
            Object.keys(payload).forEach(key => delete payload[key]);
        });
    }, 2000);
}

function tradingComputed(propName, defaultValue = 0.001) {
    return computed({
        get: () => {
            return settings.trading[brandName.value]
                ? settings.trading[brandName.value][propName] || defaultValue
                : defaultValue;
        },
        set: (value) => {
            if (!settings.trading[brandName.value]) {
                settings.trading[brandName.value] = {};
            }
            if (!payload[brandName.value]) {
                payload[brandName.value] = {};
            }
            settings.trading[brandName.value][propName] = value;
            payload[brandName.value][propName] = value;

            //emit('save', payload, 'trading');
            save();

            return true;
        }
    });
}

const cryptoFee = tradingComputed('defaultCryptoFee', 0.001);
const stockFee = tradingComputed('defaultStockFee', 0.001);
const forexFee = tradingComputed('defaultForexFee', 0.001);
const minDealAmountCrypto = tradingComputed('minDealAmountCrypto', 10);
const minDealAmountForex = tradingComputed('minDealAmountForex', 10);
const minDealAmountStock = tradingComputed('minDealAmountStock', 10);

</script>

<template>
    <fieldset class="trading general" v-bind:class="{ disabled: profile.cant('Settings TradingEdit')}">
        <legend>{{ $t('settings.trading_settings') }}</legend>

        <g-fade-container v-bind:label="brandName" icon="chart">
            <div class="brand-container">
                <label v-for="brand in profile.brands" v-bind:key="`brand${brand.id}`">
                    <input v-model="brandName" v-bind:value="brand.name" type="radio" name="brand" hidden=""/>
                    <span>{{ brand.name }}</span>
                </label>
            </div>
        </g-fade-container>

        <g-flex gap="10" justify="between" direction="column">
            <g-flex gap="10" class="wrap">
                <g-field v-model="cryptoFee"
                        label="Default Crypto Fee"
                        v-on:keypress="onlyNumberInput"
                        v-bind:readonly="profile.cant('Settings TradingEdit')">
                    <g-symbol v-if="profile.cant('Settings TradingEdit')" name="lock" width="18" height="18"/>
                </g-field>
                <g-field v-model="minDealAmountCrypto"
                        label="Min Crypto Deal Amount"
                        v-on:keypress="onlyNumberInput"
                        v-bind:readonly="profile.cant('Settings TradingEdit')">
                    <g-symbol v-if="profile.cant('Settings TradingEdit')" name="lock" width="18" height="18"/>
                </g-field>
            </g-flex>
            <g-flex justify="between" gap="10" wrap>
                <g-field v-model="forexFee"
                        label="Default Forex Fee"
                        v-on:keypress="onlyNumberInput"
                        v-bind:readonly="profile.cant('Settings TradingEdit')">
                    <g-symbol v-if="profile.cant('Settings TradingEdit')" name="lock" width="18" height="18"/>
                </g-field>
                <g-field v-model="minDealAmountForex"
                        label="Min Forex Deal Amount"
                        v-on:keypress="onlyNumberInput"
                        v-bind:readonly="profile.cant('Settings TradingEdit')">
                    <g-symbol v-if="profile.cant('Settings TradingEdit')" name="lock" width="18" height="18"/>
                </g-field>
            </g-flex>
            <g-flex justify="between" gap="10" wrap>
                <g-field v-model="stockFee"
                        label="Default Stock Fee"
                        v-on:keypress="onlyNumberInput"
                        v-bind:readonly="profile.cant('Settings TradingEdit')">
                    <g-symbol v-if="profile.cant('Settings TradingEdit')" name="lock" width="18" height="18"/>
                </g-field>
                <g-field v-model="minDealAmountStock"
                        label="Min Stock Deal Amount"
                        v-on:keypress="onlyNumberInput"
                        v-bind:readonly="profile.cant('Settings TradingEdit')">
                    <g-symbol v-if="profile.cant('Settings TradingEdit')" name="lock" width="18" height="18"/>
                </g-field>
            </g-flex>
        </g-flex>
    </fieldset>
</template>

<style scoped lang="scss">
.trading {
    padding: 10px 15px 15px;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    & > .g-flex {
        padding: 0 2px;
        margin-top: 16px;
    }
}

.g-field {
    width: calc(50% - 6px);
}

.g-fade-container {
    padding: 0 10px 0 2px;
    min-width: 160px;
    display: inline-block;
    min-height: var(--field-height, $field-height);
    background-color: var(--field-active-bg, $field-active-bg);
    border: 1px solid var(--separator-color, $separator-color);
}

.brand-container {
    padding: 5px;
    overflow: auto;
    max-height: 200px;
    background-color: var(--primary, $primary);
    border: 1px solid var(--separator-color, $separator-color);

    & > label {
        display: block;
        padding: 8px 10px;
        cursor: pointer;

        &:hover {
            color: white;
        }
    }
}
</style>